





















import { Component, Vue } from 'vue-property-decorator'
import { openURL } from 'quasar'

@Component({
  components: {
    IconBro: () => import('@/components/UI/icons/IconBro.vue'),
    TdBtn: () => import('td-ui/dist/TdBtn.vue'),
  },
})
export default class MeetingWishList extends Vue {
  private openWishList () {
    openURL('https://wishlist.tada.team/w/400/')
    window.goal('Calendar', {
      Calendar: 'Окно редактирования — клик «Вишлист»',
    })
  }
}
